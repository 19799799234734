<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Lịch sử thay thế/Lưu trữ khóa học</h1>
    </div>
    <div class="d-block">
      <v-row>
        <v-col cols="5" class="d-flex ml-2 mt-2">
          <p class="text-h6 font-weight-bold">Khóa học cũ:</p>
          <v-autocomplete
              dense outlined class="ml-1"
              v-model="archived_course_id_filter"
              :items="list_course_archived"
              item-text="name.vi"
              item-value="id"
              placeholder="Chọn khóa học ..."
              clearable
          ></v-autocomplete>
        </v-col>
        <v-col cols="5" class="d-flex ml-2 mt-2">
          <p class="text-h6 font-weight-bold">Khóa học mới:</p>
          <v-autocomplete
              dense outlined class="ml-1"
              v-model="replacement_course_id_filter"
              :items="list_course_replacement"
              item-text="name.vi"
              item-value="id"
              placeholder="Chọn khóa học ..."
              clearable
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="d-flex ml-2">
          <label class="text-h6 font-weight-bold">Ngày bắt đầu:</label>
          <div class="d-flex ml-8">
            <label class="text-body-1 font-weight-medium mb-6 pt-4">Từ:</label>
            <date-picker
                style=""
                class="ml-2 mt-3"
                v-model="date_start_filter"
                value-type="format"
                format="DD-MM-YYYY"
            />
          </div>
          <v-icon class="ml-2 mr-2 mb-3">mdi-arrow-right</v-icon>
          <div class="d-flex">
            <label class="text-body-1 font-weight-medium mb-6 pt-4">Đến:</label>
            <date-picker
                style=""
                class="ml-2 mt-3"
                v-model="date_end_filter"
                value-type="format"
                format="DD-MM-YYYY"
            />
          </div>
        </v-col>

        <v-col cols="12" class="text-center">
          <v-btn
              color="primary"
              @click="getAllCourseReplace()"
          >
            Tìm kiếm
          </v-btn>
        </v-col>

      </v-row>
      <div class="pt-5 pl-5 pr-5">
        <div>
          <v-row>
            <v-col cols="6">
              <v-pagination
                  class="mt-5"
                  color="#00BCD4"
                  style="float: left"
                  v-model="data_pagination.current_page"
                  :length="data_pagination.total_pages"
                  :total-visible="6"
              ></v-pagination>
            </v-col>
            <v-col cols="12">
              <div class="table-responsive">
                <table
                    class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
                >
                  <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Khóa học cũ</th>
                    <th scope="col">Khóa học mới</th>
                    <th scope="col">Ngày bắt đầu</th>
                    <th scope="col">Trạng thái</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(courseReplace, courseReplacementIndex) in courseReplacements">
                    <tr :key="courseReplacementIndex">
                      <td scope="row">{{ courseReplacementIndex + 1 }}</td>
                      <td>
                        <p
                            class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          <template v-if="courseReplace.archived_course?.name?.vi">
                            {{ courseReplace.archived_course.name.vi }}
                          </template>
                          <template v-else-if="courseReplace.archived_course?.name?.en">
                            {{ courseReplace.archived_course.name.en }}
                          </template>
                          <template v-else>
                            (none)
                          </template>
                        </p>
                      </td>
                      <td>
                        <p
                            class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          <template v-if="courseReplace.replacement_course?.name?.vi">
                            {{ courseReplace.replacement_course.name.vi }}
                          </template>
                          <template v-else-if="courseReplace.replacement_course?.name?.en">
                            {{ courseReplace.replacement_course.name.en }}
                          </template>
                          <template v-else>
                            (none)
                          </template>
                        </p>
                      </td>
                      <td>
                        <p
                            class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ courseReplace.replacement_date }}
                        </p>
                      <td>
                      <v-chip label outlined color="gray" text-color="gray" v-if="courseReplace.status === STATUS_CONST.PENDING">Pending</v-chip>
                      <v-chip label outlined color="yellow" text-color="yellow" v-if="courseReplace.status === STATUS_CONST.IN_PROGRESS">In Progress</v-chip>
                      <v-chip label outlined color="green" text-color="green" v-if="courseReplace.status === STATUS_CONST.DONE">Đã thay thế</v-chip>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!courseReplacements.length">
                    <tr>
                      <td style="text-align: center" colspan="11">No data</td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </v-col>
            <v-col cols="6">
              <v-pagination
                  style="float: left"
                  color="#00BCD4"
                  v-model="data_pagination.current_page"
                  :length="data_pagination.total_pages"
                  :total-visible="6"
              ></v-pagination>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "CourseReplacementList",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      courseReplacements: [],
      data_pagination: {
        current_page: 1,
        total_pages: 1,
      },
      STATUS_CONST: {
        PENDING: 1,
        IN_PROGRESS: 2,
        DONE: 3,
      },
      list_course_replacement: [],
      list_course_archived: [],
      archived_course_id_filter: null,
      replacement_course_id_filter: null,
      date_start_filter: null,
      date_end_filter: null,
    };
  },
  watch: {
    "data_pagination.current_page": function (page) {
      this.getAllCourse(page);
    },
  },
  created() {
    this.getAllCourseReplace();
    this.getListCourseReplacement();
    this.getListCourseArchived();
  },
  methods: {
    async getAllCourseReplace(page = 1) {
      let vm = this;
      vm.is_call_api = true;
      let url = `prep-app/courses/replacement/history?page=${page}`;
      if (this.replacement_course_id_filter) {
        url += `&replacement_course_id=${this.replacement_course_id_filter}`;
      }
      if (this.archived_course_id_filter) {
        url += `&archived_course_id=${this.archived_course_id_filter}`;
      }
      if (this.date_start_filter) {
        url += `&date_start=${this.date_start_filter}`;
      }
      if (this.date_end_filter) {
        url += `&date_end=${this.date_end_filter}`;
      }
      await ApiService.get(url)
          .then(function (response) {
            if (response.status === 200) {
              vm.courseReplacements = response.data.data;
              vm.data_pagination = response.data.meta.pagination;
              vm.is_call_api = false;
            }
          });
    },
    async getListCourseReplacement() {
      let vm = this;
      let url = `prep-app/courses/limit`;
      await ApiService.get(url)
          .then(function (response) {
            if (response.status === 200) {
              vm.list_course_replacement = response.data;
            }
          });
    },
    async getListCourseArchived() {
      let vm = this;
      let url = `prep-app/courses/limit?is_archived=1`;
      await ApiService.get(url)
          .then(function (response) {
            if (response.status === 200) {
              vm.list_course_archived = response.data;
            }
          });
    },
  },
}
</script>

<style scoped>

</style>